<script lang="ts">
  import { INVITE_URL } from "$lib/constants";
  import { FancyBackground, MetaTags, PageFooter } from "$lib/components";
</script>

<MetaTags
  title="Tupperbox"
  description="Tupperbox is a bot that allows you to create and talk through fake bot profiles. Great for plural users and roleplay!"
/>

<main id="app-content" class="overflow-auto m-0">
  <FancyBackground>
    <div class="hero">
      <div class="inner">
        <h1 class="d-md-none">Tupperbox</h1>
        <img alt="Tupperbox" src="/images/tbox_colorful.png" />
        <div class="hero-title">
          <h1 class="d-none d-md-block">Tupperbox</h1>
          <div class="hero-links">
            <a class="btn btn-secondary" href={INVITE_URL}>
              <i class="fas fa-plus" /> Add To Server
            </a>
            <a class="btn btn-primary" href="/dashboard/list">
              <i class="fas fa-cog" /> Manage
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="landing-list" class="container">
      <div class="row">
        <div class="col-md-6">
          <h3>Switch profiles on the fly</h3>
          <p>
            Tupperbox lets you have many different profiles ("Tuppers") which
            you can choose between easily with every message you send!
          </p>
          <h4>Why?</h4>
          <ul>
            <li>Immersive roleplay as your favorite characters!</li>
            <li>
              For plural users, let your system members express themselves
              naturally!
            </li>
            <li>Play out scenarios as your DnD characters!</li>
            <li>
              Express your creativity by acting out scenes or making instant
              memes!
            </li>
          </ul>
        </div>
        <div class="col-md-6 video-container">
          <video autoplay loop muted src="/images/marketing/tbox-demo.webm" />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 video-container">
          <video
            autoplay
            loop
            muted
            src="/images/marketing/tbox-list-demo.webm"
          />
        </div>
        <div class="col-md-6 order-first order-md-last">
          <h3>Create and edit Tuppers</h3>
          <p>
            A tupper is a fake Discord profile you can send messages through, as
            long as Tupperbox also has access to that channel.<br />
            You pick a tupper to use by writing its "brackets" around your message.
            It's useful to think of the brackets as a special prefix for your tupper.
          </p>
          <h4>Tupper features</h4>
          <ul>
            <li>Change your tuppers' names or avatars</li>
            <li>Add nicknames and tags to customize your tuppers' names</li>
            <li>Organize tuppers into groups</li>
            <li>Import tuppers from compatible bots like PluralKit</li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-5">
          <h3>Web Dashboard</h3>
          <p>
            Manage your tuppers and groups with the
            <a href="/dashboard/list">online dashboard!</a>
          </p>
          <ul>
            <li>Reorder tuppers and groups</li>
            <li>Remove multiple tuppers</li>
            <li>Edit tupper and group info</li>
          </ul>
        </div>
        <div class="col-md-7 video-container">
          <video
            autoplay
            loop
            muted
            src="/images/marketing/tbox-dashboard-demo.webm"
          />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 video-container">
          <video
            autoplay
            loop
            muted
            src="/images/marketing/tbox-proxy-demo.webm"
          />
        </div>
        <div class="col-md-6 order-first order-md-last">
          <h3>Proxy Features</h3>
          <p>
            A proxy is a message sent through a tupper. You can interact with
            these messages through various reactions.
          </p>
          <ul>
            <li>React with ❌ to a proxy you've sent to delete it!</li>
            <li>React with ❓ to a proxy to show who sent it in DM!</li>
            <li>React with 📝 to edit a proxy you've sent!</li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <h3>Autoproxy</h3>
          <p>
            Set a tupper to autoproxy and every regular message you send will be
            from that tupper!
          </p>
          <ul>
            <li>Use "sticky mode" to autoproxy as the last tupper used!</li>
            <li>Turn on autoproxy in a specific server or even channel</li>
            <li>
              Start a message with \ to disable autoproxy for that message
            </li>
            <li>
              Start a message with \\ to clear a stickied tupper until next
              proxy
            </li>
          </ul>
        </div>
        <div class="col-md-6 video-container">
          <video
            autoplay
            loop
            muted
            src="/images/marketing/tbox-auto-demo.webm"
          />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 text-center">
          <img
            src="/images/marketing/tbox-log-demo.webp"
            alt="Tupperbox logs"
            class="w-75"
          />
        </div>
        <div class="col-md-6 order-first order-md-last">
          <h3>Logging</h3>
          <p>
            Set a log channel to have Tupperbox copy all proxied messages to it.
          </p>
          <p>
            Ideal for moderators to quickly check what a user has said as a
            particular tupper. Works with Discord search bar!
          </p>
        </div>
      </div>
    </div>
    <PageFooter />
  </FancyBackground>
</main>

<style lang="scss">
  #landing-list {
    background-color: $bg;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    padding: 2rem;

    > .row {
      justify-content: center;
      align-items: center;
    }

    > hr {
      margin: 2rem 0;
    }

    .video-container {
      text-align: center;

      @include screen-md {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      @include screen-lg {
        padding-left: 3rem;
        padding-right: 3rem;
      }

      > video {
        width: 100%;
      }
    }
  }

  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-bottom: 4rem;

    @include screen-md {
      width: auto;
      margin: 0rem;
      margin-bottom: 10rem;
      margin-top: 6rem;
    }

    > .inner {
      background-color: $bg;
      border-radius: 2.5rem;
      padding: 2.5rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      margin-top: 3rem;

      @include screen-md {
        flex-direction: row;
        border-radius: 3rem;
        padding: 1.5rem 3rem;
        gap: 2rem;
        margin-top: 0;
      }

      > h1 {
        font-size: clamp(24px, 10vw, 48px);
        margin-bottom: 0;
      }

      img {
        width: 200px;
      }

      .hero-title {
        h1 {
          text-align: center;
        }
        .hero-links {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 1rem;

          @include screen-md {
            flex-direction: row;
          }

          a {
            width: 100%;
            @include screen-md {
              width: auto;
            }
          }
        }
      }
    }
  }
</style>
